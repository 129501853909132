<template>
  <div class="recharge">
    <van-nav-bar :title="$t('newAdd.Recharge')">
      <van-icon slot="left" name="arrow-left" color="#fff" @click="$router.back()" />
    </van-nav-bar>
    <div class="content-wrap">
      <div class="padding16">
        <div class="content white-back">
          <div class="payNum font14">
            {{ $t("newAdd.RechargeAmount") }}：
            <span class="color-red font22">{{ payData.money }}</span>
          </div>
          <div class="top6 width100pre">
            <div class="font14 address-wrap">
              {{ $t("newAdd.depositAddress") }}：
            </div>
            <div class="address_value_wrap" @click="copy">
              <div class="value font14">{{ payData.url }}</div>
              <div class="copy">
                <svg t="1651894560362" class="icon" viewBox="0 0 1024 1024" version="1.1"
                  xmlns="http://www.w3.org/2000/svg" p-id="1900" width="18" height="18">
                  <path
                    d="M878.250667 981.333333H375.338667a104.661333 104.661333 0 0 1-104.661334-104.661333V375.338667a104.661333 104.661333 0 0 1 104.661334-104.661334h502.912a104.661333 104.661333 0 0 1 104.661333 104.661334v502.912C981.333333 934.485333 934.485333 981.333333 878.250667 981.333333zM375.338667 364.373333a10.666667 10.666667 0 0 0-10.922667 10.965334v502.912c0 6.229333 4.693333 10.922667 10.922667 10.922666h502.912a10.666667 10.666667 0 0 0 10.922666-10.922666V375.338667a10.666667 10.666667 0 0 0-10.922666-10.922667H375.338667z"
                    p-id="1901" fill="#333"></path>
                  <path
                    d="M192.597333 753.322667H147.328A104.661333 104.661333 0 0 1 42.666667 648.661333V147.328A104.661333 104.661333 0 0 1 147.328 42.666667H650.24a104.661333 104.661333 0 0 1 104.618667 104.661333v49.962667c0 26.538667-20.309333 46.848-46.848 46.848a46.037333 46.037333 0 0 1-46.848-46.848V147.328a10.666667 10.666667 0 0 0-10.922667-10.965333H147.328a10.666667 10.666667 0 0 0-10.965333 10.965333V650.24c0 6.229333 4.693333 10.922667 10.965333 10.922667h45.269333c26.538667 0 46.848 20.309333 46.848 46.848 0 26.538667-21.845333 45.312-46.848 45.312z"
                    p-id="1902" fill="#333"></path>
                </svg>
              </div>
            </div>
          </div>
          <div class="coin_amount_wrap font14 top6">
            {{ `${coinConfig.type} ${$t("newAdd.Amount")}` }}：
            <span class="color-red font14">{{
      `≈${coinAmount} ${coinConfig.type}`
    }}</span>
            <span class="coin_copy" @click="coinCopy">
              <svg t="1651894560362" class="icon" viewBox="0 0 1024 1024" version="1.1"
                xmlns="http://www.w3.org/2000/svg" p-id="1900" width="18" height="18">
                <path
                  d="M878.250667 981.333333H375.338667a104.661333 104.661333 0 0 1-104.661334-104.661333V375.338667a104.661333 104.661333 0 0 1 104.661334-104.661334h502.912a104.661333 104.661333 0 0 1 104.661333 104.661334v502.912C981.333333 934.485333 934.485333 981.333333 878.250667 981.333333zM375.338667 364.373333a10.666667 10.666667 0 0 0-10.922667 10.965334v502.912c0 6.229333 4.693333 10.922667 10.922667 10.922666h502.912a10.666667 10.666667 0 0 0 10.922666-10.922666V375.338667a10.666667 10.666667 0 0 0-10.922666-10.922667H375.338667z"
                  p-id="1901" fill="#333"></path>
                <path
                  d="M192.597333 753.322667H147.328A104.661333 104.661333 0 0 1 42.666667 648.661333V147.328A104.661333 104.661333 0 0 1 147.328 42.666667H650.24a104.661333 104.661333 0 0 1 104.618667 104.661333v49.962667c0 26.538667-20.309333 46.848-46.848 46.848a46.037333 46.037333 0 0 1-46.848-46.848V147.328a10.666667 10.666667 0 0 0-10.922667-10.965333H147.328a10.666667 10.666667 0 0 0-10.965333 10.965333V650.24c0 6.229333 4.693333 10.922667 10.965333 10.922667h45.269333c26.538667 0 46.848 20.309333 46.848 46.848 0 26.538667-21.845333 45.312-46.848 45.312z"
                  p-id="1902" fill="#333"></path>
              </svg>
            </span>
          </div>
          <div class="flex center-hor top10" v-if="!!payData.qrimg">
            <van-image width="19rem" height="19rem" :src="payData.qrimg" />
          </div>
        </div>
        <div class="step-wrap top16">
          <div class="font14">{{ $t("newAdd.rechargeSteps") }}：</div>
          <div class="font12 line1 top10">
            <div v-for="(item, index) in steps" :key="index">
              {{ index + 1 }}.{{ item }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fixed btn-wrap flex">
      <!-- <div class="right" @click="downloadQr">
        {{ $t("newAdd.rechargeNow") }}
      </div> -->
      <div class="left" @click="confirm" style="width: 100%">
        {{ $t("newAdd.completePayment") }}
      </div>
    </div>
  </div>
</template>

<script>
import { Icon, NavBar, Image as VanImage, Toast } from "vant";
import download from "downloadjs";
import { RECHARGE_COMMIT } from "@/api";
export default {
  components: {
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [VanImage.name]: VanImage,
    Toast,
  },
  data() {
    return {
      payData: {},
      steps: [],
      coinConfig: {},
      coinAmount: "0.00",
      imageType: "png",
    };
  },
  methods: {
    downloadQr() {
      this.imageType = this.payData.qrimg.substring(
        this.payData.qrimg.length - 3
      );
      Toast(this.$t("newAdd.toCutScreen"));
      // download(this.payData.qrimg, `myCode.${this.imageType}`, "image");
    },
    copy() {
      this.$copyText(this.payData.url).then((e) => {
        Toast("Copy successfully");
      });
    },
    coinCopy() {
      this.$copyText(this.coinAmount).then((e) => {
        Toast("Copy successfully");
      });
    },
    confirm() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      RECHARGE_COMMIT({
        id: this.payData.id,
        money: this.payData.money,
        account: this.payData.account,
        isbefore: 1,
      })
        .then((r) => {
          Toast.clear();
          if (r.data.ret === 1) {
            Toast.clear();
            // Toast
            // this.$router.back();
            this.$router.push({ name: "RechargeList" });
            // window.location.href = r.data.data.url;
          } else {
            // Toast('System exception, please try again later')
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          Toast.clear();
          console.error(e);
        });
    },
    fixed(num, digits) {
      const dig = 10 ** digits;
      const res = Math.round(num * dig) / dig;
      return res > 0 && !!res ? res : "0.00";
    },
  },
  created() {
    let payData = this.$route.params.payData;
    if (payData) {
      this.payData = payData;
      this.$cookie.set("qrPayData", JSON.stringify(this.payData));
    } else {
      this.payData = JSON.parse(this.$cookie.get("qrPayData"));
    }
    this.steps = this.payData.step.split(/[0-9]./);
    for (let i = 0; i < this.steps.length; i++) {
      let item = this.steps[i];
      if (item == "") {
        this.steps.splice(i, 1);
      }
    }
    this.coinConfig = this.payData.setting;
    this.coinAmount = this.fixed(
      this.payData.money / this.coinConfig.coin_rate,
      8
    );
    console.log(this.coinConfig);
  },
  mounted() { },
  destroyed() {
    this.$cookie.remove("qrPayData");
  },
};
</script>

<style lang="less" scoped>
.recharge {
  position: relative;
  // font-family: "PingFang-Regular";
  height: 100%;

  .van-nav-bar {
    background-color: #ff0000;

    /deep/ .van-nav-bar__content {
      background-color: #ff0000;
    }

    /deep/.van-nav-bar__title {
      color: #fff;
    }

    button {
      background-color: #ff0000;
      border: none;
      color: #fff;
      font-size: calc(10rem / 16);
    }

    .iconfont {
      font-size: calc(22rem / 16);
      color: #fff;
    }
  }

  .content-wrap {
    width: 100vw;
    min-height: 100vh;
    background-color: #f4f8f9;
    position: relative;
    overflow: hidden;

    .content {
      padding: 1.375rem 0.875rem;
    }
  }
}

.address_value_wrap {
  width: 100%;
  border: 1px solid #ff0000;
  min-height: 2.5rem
    /* 40/16 */
  ;
  border-radius: 4px;
  position: relative;

  >.value {
    line-height: 1.25rem
      /* 20/16 */
    ;
    padding: 0.625rem
      /* 10/16 */
    ;
    width: calc(100% - 2.5rem
        /* 40/16 */
      );
    word-break: break-all;
  }

  >.copy {
    position: absolute;
    bottom: 0;
    right: 0.625rem
      /* 10/16 */
    ;
  }
}

.coin_amount_wrap {
  display: flex;

  >.coin_copy {
    margin-left: 1rem
      /* 16/16 */
    ;
  }
}
</style>

<style scoped>
.top10 {
  margin-top: 0.625rem;
}

.top6 {
  margin-top: 0.375rem;
}

.top16 {
  margin-top: 1rem;
}

.padding16 {
  padding: 1rem;
}

.padding10 {
  padding: 0.625rem;
}

.white-back {
  background-color: #fff;
}

.font12 {
  font-size: 0.75rem;
}

.font14 {
  font-size: 0.875rem;
}

.font16 {
  font-size: 1rem;
}

.font18 {
  font-size: 1.125rem;
}

.font22 {
  font-size: 1.375rem;
}

.color-red {
  color: #ff0000;
}

.flex {
  display: flex;
}

.between {
  justify-content: space-between;
}

.width100pre {
  width: 100%;
}

.address-wrap {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.copy {}

.center-ver {
  align-items: center;
}

.center-hor {
  justify-content: center;
}

.line1 {
  line-height: 1.1;
}

.fixed {
  position: fixed;
}

.btn-wrap {
  bottom: 0;
  width: 100vw;
}

.btn-wrap>.left,
.right {
  width: 50vw;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.btn-wrap>.left {
  background-color: #3eb3ff;
}

.btn-wrap>.right {
  background-color: #dc1323;
}
</style>